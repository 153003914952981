// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-activity-article-book-video-detail-page-js": () => import("./../../../src/templates/activity-article-book-video-detail-page.js" /* webpackChunkName: "component---src-templates-activity-article-book-video-detail-page-js" */),
  "component---src-templates-activity-or-video-directory-js": () => import("./../../../src/templates/activity-or-video-directory.js" /* webpackChunkName: "component---src-templates-activity-or-video-directory-js" */),
  "component---src-templates-dragon-breathing-js": () => import("./../../../src/templates/dragon-breathing.js" /* webpackChunkName: "component---src-templates-dragon-breathing-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-fatherhood-js": () => import("./../../../src/templates/fatherhood.js" /* webpackChunkName: "component---src-templates-fatherhood-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/not-found-page.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-detail-page-js": () => import("./../../../src/templates/service-detail-page.js" /* webpackChunkName: "component---src-templates-service-detail-page-js" */),
  "component---src-templates-services-directory-js": () => import("./../../../src/templates/services-directory.js" /* webpackChunkName: "component---src-templates-services-directory-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-topic-page-js": () => import("./../../../src/templates/topic-page.js" /* webpackChunkName: "component---src-templates-topic-page-js" */)
}

